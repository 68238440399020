<mat-toolbar color="accent" class="d-print-none">

    <div class="nav-bar-left">

        <!-- HOME BUTTON -->
        <ng-container *ngIf="useSidebarMenu; else useHomeButtonOnly">
            <button
                *ngIf="hasMenu"
                type="button"
                mat-icon-button
                (click)="toggleSidebar()"
                ><i class="fas fa-bars fa-lg" title="Menu"></i>
            </button>
        </ng-container>
        <ng-template #useHomeButtonOnly>
            <button
                *ngIf="(brandInactive | async) === false"
                type="button"
                mat-icon-button
                [routerLink]="tenant"
            >
                <i class="fas fa-home fa-lg" title="{{ 'System.Home' | translate }}"></i>
            </button>
        </ng-template>
        <span class="company-name">{{ properCompanyName() }}</span>
    </div>

    <!-- LOGO -->
    <div class="main-logo" style="position: relative">
        <a
            type="button"
            mat-raised-button
            color="basic"
            class="px-sm-5 py-1 px-1 logo-button"
            [routerLink]="(brandInactive | async) === false ? '/' : '/login'"
        >
            <img
                [src]="companyLogo"
                class="logo logo-img"
                [alt]="currentCompany?.name"
                [title]="companyName"
                data-cy="nav-bar-logo"
        /></a>
    </div>

    <div class="nav-bar-right">
        <!-- ACTIONS -->
        <div id="nav-actions-container">
            <!-- content will be placed dynamically via Cdk Portal -->
        </div>

        <button
            *ngIf="hasFollowupsMenu"
            type="button"
            aria-label="Followups"
            mat-icon-button
            color="basic"
            (click)="sidenav.toggle()"
        >
            <i
                class="fas fa-calendar-check fa-lg"
                title="{{ 'System.Followups' | translate }}"
                [matBadgeHidden]="!followupCount"
                [matBadge]="followupCount"
                matBadgePosition="after"
                matBadgeColor="warn"
                matBadgeOverlap="false"
            ></i>
        </button>
        <button
            *ngIf="showUserMenu"
            [matMenuTriggerFor]="userMenu"
            type="button"
            aria-label="User Menu"
            mat-icon-button
            color="basic"
            data-cy="user-menu-button"
        >
            <i class="fas fa-user fa-lg" title="{{ 'JobSummary.User' | translate }}"></i>
        </button>
        <mat-menu #userMenu="matMenu" class="py-0" data-cy="user-menu">
            <mat-action-list *ngIf="username | async as uName; else noUser">
                <button
                    *ngIf="tenant"
                    mat-list-item
                    [routerLink]="[tenant, 'admin', 'options']"
                    data-cy="options"
                >
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-cog']"
                    ></i>
                    &nbsp;{{ 'User.Options'| translate }}
                </button>
                <button mat-list-item type="button" (click)="logout()" data-cy="logout">
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-sign-out-alt']"
                    ></i>
                    &nbsp;{{ 'User.SignOut' | translate }} {{ uName }}
                </button>
                <button *ngIf="previousUser()" mat-list-item type="button" (click)="loginAsPreviousUser()" data-cy="previous-user">
                    <i
                        matListIcon
                        [ngClass]="['fa-fw text-primary', 'fas fa-user']"
                    ></i>
                    &nbsp;{{ 'User.LoginAsPrevious' | translate }} {{ previousUser() }}
                </button>
            </mat-action-list>
            <ng-template #noUser>
                <mat-action-list>
                    <button mat-list-item type="button" (click)="login()" data-cy="sign-in">
                        <i
                            matListIcon
                            [ngClass]="['fa-fw text-primary', 'fas fa-sign-in-alt']"
                        ></i>
                        &nbsp;{{ 'User.SignIn' | translate }}
                    </button>
                </mat-action-list>
            </ng-template>
        </mat-menu>
    </div>
</mat-toolbar>
